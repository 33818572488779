import React from "react";
import { useNavigate } from "react-router-dom";
// import { useLocalStoraje } from "./useLocalStoraje";

import img0 from '../../Assets/Media/person/ahorcado_0.png'
import img1 from '../../Assets/Media/person/ahorcado_1.png'
import img2 from '../../Assets/Media/person/ahorcado_2.png'
import img3 from '../../Assets/Media/person/ahorcado_3.png'
import img4 from '../../Assets/Media/person/ahorcado_4.png'
import img5 from '../../Assets/Media/person/ahorcado_5.png'
import img6 from '../../Assets/Media/person/ahorcado_6.png'

const TwoPlayerContext = React.createContext();

const time_minutes = 1; // Value in minutes
const time_seconds = 30; // Value in seconds
const duration = time_minutes * 60 + time_seconds;
var secondsRemaining = duration;


function TwoPlayerProvider(props) {
    let navigate = useNavigate();

    const [wordValue, setWordValue] = React.useState("");
    const [arrayLetterPush, setArrayLetterPush] = React.useState([]);
    const [showWord, setShowWord] = React.useState('')
    const [showImg, setshowImg] = React.useState(img0)
    const [gameOver, setGameOver] = React.useState(false)
    const [timeLimited, setTimeLimited] = React.useState(0)
    const [time, setTime] = React.useState('')
    const [gameWin, setGameWin] = React.useState(false)
    let [countError, setCountError] = React.useState(0)

    const onGameWin = () => {
        setGameWin(true);
    }

    const onGameOver = () => {
        setGameOver(true)
    }

    function showLine() {
        //   var pista = document.getElementById("pista");
        let texto = "";
        let longitud = wordValue.length;

        for (let i = 0; i < longitud; i++) {
            texto += "_";
        }
        setShowWord(texto)
    }

    const onSavePushLetther = (letter) => {
        let arrayTem = [];
        arrayTem = arrayLetterPush;
        arrayTem.push(letter)
        let jsonString = JSON.stringify(arrayTem);
        setArrayLetterPush(JSON.parse(jsonString));
        findWord(letter)
    }

    const findWord = (newLetter) => {
        setShowWord('')
        let upperWord = wordValue.toUpperCase()
        if (upperWord.includes(newLetter)) {
            let word = '';
            for (let index in upperWord) {
                let item = upperWord[index]
                if (item === newLetter) {
                    word += newLetter
                } else {
                    let text = showWord.replaceAll(' ', '')
                    if (text[index] !== '_') {
                        word += showWord[index]
                    } else {
                        word += '_'
                    }
                }
            }
            setShowWord(word)

            if (!word.includes('_')) {
                onGameWin();
            }

        } else {
            let word = JSON.stringify(showWord)
            let wordParse = JSON.parse(word)
            setShowWord(wordParse);
            setCountError(countError += 1);
            sowImgError();
        }
    }

    const sowImgError = () => {
        if (countError == 1) {
            setshowImg(img1);
        } else if (countError == 2) {
            setshowImg(img2);
        } else if (countError == 3) {
            setshowImg(img3);
        } else if (countError == 4) {
            setshowImg(img4);
        } else if (countError == 5) {
            setshowImg(img5);
        } else if (countError == 6) {
            setshowImg(img6);
            onGameOver()
        }
    }

    function onReturn(link) {
        onReset();
        navigate(link)
    }

    function onViewHome() {
        setGameOver(false)
        onReset();
        navigate('/')
    }

    function onViewTwoPlayerWin() {
        setGameWin(false)
        onReset();
        navigate('/twoPlayer/CustomWord')
    }

    function onReset() {
        setTime('')
        setCountError(0)
        setArrayLetterPush([])
        setWordValue('')
        setshowImg(img0)
        setGameWin(false)
        setGameOver(false)
        setTimeLimited('')
    }

    const onTimer = () => {
        let min = 0;
        let sec = 0;
        let time = '';

        if (secondsRemaining > 0) {
            let countInterval = setInterval(function () {
                if (secondsRemaining >= 0) {
                    min = parseInt(secondsRemaining / 60);
                    sec = parseInt(secondsRemaining % 60);

                    time = `${paddedFormat(min)}:${paddedFormat(sec)}`;
                    console.log(time);
                    setTimeLimited(time);
                    secondsRemaining -= 1;
                    console.log(secondsRemaining);
                } else {
                    clearInterval(countInterval)
                }

                // if (secondsRemaining < 0) { clearInterval(countInterval) };
            }, 1000);
        }
    }

    function paddedFormat(num) {
        return num < 10 ? "0" + num : num;
    }

    return (
        <TwoPlayerContext.Provider
            value={{
                wordValue,
                arrayLetterPush,
                showWord,
                showImg,
                gameOver,
                gameWin,
                time,
                timeLimited,

                setTimeLimited,
                setTime,
                setGameOver,
                setWordValue,
                showLine,
                onReset,
                onReturn,
                onSavePushLetther,
                onViewHome,
                onViewTwoPlayerWin,
                onGameOver,
                onGameWin,
                onTimer,
            }}
        >
            {props.children}
        </TwoPlayerContext.Provider>
    );
}
export { TwoPlayerContext, TwoPlayerProvider };