import React from "react";
// import { Header } from "../../Components/Header/Header";
// import { Time } from "../../Components/Time/Time";

import './OnePlayer.css'

function OnePlayer() {
    return (
        <>
            {/* <Header>
                <Time time='02:43' />
            </Header> */}
            <section>
                un jugador
            </section>
        </>
    );
}

export { OnePlayer };