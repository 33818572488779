import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";

import { LayoutStart } from "../Containers/Layout/LayoutStart";
import { Home } from '../Pages/Home/Home'
import { OnePlayer } from "../Pages/OnePlayer/OnePlayer";
import { TwoPlayer } from "../Pages/TwoPlayer/TwoPlayer";
import { CustomWord } from "../Containers/CustomWord/CustomWord";
import { Game } from "../Containers/Game/Game";

function Router() {
    /* 
        ################### RUTAS VALIDAS
      /groups
      /groups/admin
      /users/:id
      /users/:id/messages
      /files/*
      /files/:id/*
    
      https://reactrouter.com/en/main/upgrading/v5    
    */

    return (
        <BrowserRouter>
            <LayoutStart>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/onePlayer" element={<OnePlayer />} errorElement={<ErrorBoundary />} />
                    <Route path="/twoPlayer" element={<TwoPlayer />} errorElement={<ErrorBoundary />} />
                    <Route path="/twoPlayer/CustomWord" element={<CustomWord />} errorElement={<ErrorBoundary />} />
                    <Route path="/twoPlayer/Game" element={<Game />} errorElement={<ErrorBoundary />} />
                    {/* <Route path="CustomWord" element={<TwoPlayer />} errorElement={<ErrorBoundary />} /> */}
                    {/* <Layout> </Layout> */}
                    {/* <Route path="users/*" element={<Users />} /> */}
                </Routes>
            </LayoutStart>
        </BrowserRouter>
    );
}

function ErrorBoundary() {
    // let error = useRouteError();
    // console.error(error);
    // Uncaught ReferenceError: path is not defined
    return <div>Dang!</div>;
}


// function Users() {
//     return (
//         <div>
//             My Profile
//             <nav>
//                 <ul>
//                     <li><Link to="34">My Profile by id</Link></li>
//                     <li><Link to="me">My Profile</Link></li>
//                 </ul>

//             </nav>

//             <Routes>
//                 <Route path=":id" element={<UserProfile />} />
//                 <Route path="me" element={<OwnUserProfile />} />
//             </Routes>
//         </div>
//     );
// }
// function UserProfile() {
//     return (
//         <div>
//             Mi nombre
//         </div>
//     );
// }
// function OwnUserProfile() {
//     return (
//         <div>
//             Mi apellido
//         </div>
//     );
// }
export { Router }