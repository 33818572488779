import React from "react";
import './Layout.css'
import { GameOver } from '../../Components/GameOver/GameOver'
import { Win } from '../../Components/Win/Win'

function Layout({ children }) {
    return (
        <>
            <section className="ahorcado_container">
                <section className="ahorcado_view">
                    <div className="ahorcado_view-fondo">
                        <h1 className="text_center title">Juego del ahorcado</h1>
                        {children}
                    </div>
                </section>
                <GameOver />
                <Win />
            </section>
        </>
    )
}

export { Layout };