import React from "react";
import './ButtonIcon.css'

//https://icons.getbootstrap.com
function ButtonIcon({ nameIcon, classIcon = '', handleClick }) {

    const click = () => {
        if (!!handleClick) {
            handleClick()
        }
    }

    return (
        <div className="container_buttonHome" onClick={click}>
            <i className={`icon-bi bi ${nameIcon} ${classIcon}`}></i>
        </div>
    )
}

export { ButtonIcon }