import React from "react";
import { ButtonIcon } from "../ButtonIcon/ButtonIcon";

import './Footer.css'

function Footer({ ShowReturn = false, ShowHome = false, ShowSettings = false, hangleReturn, hangleHome }) {

    const onClickReturn = () => {
        if (!!hangleReturn) hangleReturn()
    }
    const onClickHome = () => {
        if (!!hangleHome) hangleHome()
    }
    const onClickSettings = () => {
        const vatTem = false
        if (vatTem) hangleHome()
    }

    return (
        <section className="Footer">
            {ShowReturn && <ButtonIcon handleClick={onClickReturn} nameIcon='bi-arrow-90deg-left' classIcon="Footer-iconHome" />}
            {ShowHome && <ButtonIcon handleClick={onClickHome} nameIcon='bi-house' classIcon="Footer-iconHome" />}
            {ShowSettings && <ButtonIcon handleClick={onClickSettings} nameIcon='bi-gear' classIcon="Footer-iconHome" />}
        </section>
    );
}

export { Footer }