import React from "react";
import './Win.css'
import { TwoPlayerContext } from "../../Contexts/TwoPlayerContext/TwoPlayerContext";
import { Button } from "../Button/Button";
import { Footer } from "../Footer/Footer";

function Win() {
    const {
        gameWin,
        onViewHome,
        onReturn,
        onViewTwoPlayerWin,
        time,
    } = React.useContext(TwoPlayerContext);

    const viewHome = () => {
        onViewHome()
    }

    const viewReturn = () => {
        onReturn('/twoPlayer')
    }
    return (
        gameWin &&
        <>
            <div className="gamewin_modal">
                <div className="gamewin_modal-body">
                    <h1 className="gamewin__Message">Ganaste</h1>
                    <h4 className="text_center">Tiempo realizado {time} min</h4>
                    <div className="gamewin_constBtn">
                        <Button handleClick={onViewTwoPlayerWin} name='Jugar de nuevo' />
                    </div>
                    <Footer
                        ShowReturn={true}
                        hangleReturn={viewReturn}
                        ShowHome={true}
                        hangleHome={viewHome}
                    />
                </div>
            </div>
        </>
    )
}

export { Win };