import React from "react";
import { useNavigate } from "react-router-dom";
import { TwoPlayerContext } from "../../Contexts/TwoPlayerContext/TwoPlayerContext";
import './Game.css'
import { Abecedario } from "../../Components/Abecedario/Abecedario";
import { Footer } from "../../Components/Footer/Footer";
import { Time } from "../../Components/Time/Time";


function Game() {
    let navigate = useNavigate();
    const {
        onReset,
        wordValue,
        showLine,
        showWord,
        showImg,
        onViewHome,
        onTimer,
    } = React.useContext(TwoPlayerContext);

    const viewHome = () => {
        onViewHome()
    }
    const viewReturn = () => {
        onReset();
        navigate('/twoPlayer/CustomWord')
    }

    React.useEffect(() => {
        onTimer();
        if (wordValue == '') {
            navigate('/twoPlayer/CustomWord')
        } else {
            showLine()
        }
    }, [])


    return (
        <>
            {/* <Time /> */}
            <section className="ahorcado_adivinaAdivina">
                <div className="adivinaAdivina_groupImg">
                    <img src={showImg} alt="" id="image0" />
                </div>
                <div className="adivinaAdivina_lineaPalabra">
                    <div className="adivinaAdivina_lineaPalabra_container">
                        <h2>{showWord}</h2>
                    </div>
                </div>
                <Abecedario />
            </section>
            <Footer
                ShowReturn={true}
                hangleReturn={viewReturn}
                ShowHome={true}
                hangleHome={viewHome}
            />

        </>
    )
}

export { Game };