import React from "react";
import { Link } from "react-router-dom";
import './Home.css'

function Home() {
    return (
        <>
            <section class="ahorcado_integrantes">
                <div class="ahorcado_integrantes_ccont">
                    <div class="preguntaIntegrantes">Vamos a jugar</div>
                    <div class="ahorcado-eligeIntegrantes">
                        {/* <Link to="onePlayer" class="ahorcado-botonIntegrantes">1 Jugador</Link> */}
                        <Link to="twoPlayer" class="ahorcado-botonIntegrantes">2 Jugadores</Link>
                    </div>
                </div>
            </section>
        </>
    );
}

export { Home };