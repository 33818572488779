import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "../../Components/Button/Button";
import { Footer } from "../../Components/Footer/Footer";
import './TwoPlayer.css'

function TwoPlayer() {
    let navigate = useNavigate();
    const viewHome = () => {
        navigate('/')
    }

    return (
        //opc1 Palabra personalizada
        //opc2 Supervivencia
        <>
            <section className="TwoPlayer">
                <Link to='CustomWord'>
                    <Button classButon="mb-5 btnColorGreen" name='Palabra personalizada' />
                </Link>
                {/* <Link to='Survival'>
                    <Button name='Supervivencia' />
                </Link> */}
            </section>

            <Footer
                ShowHome={true}
                hangleHome={viewHome}
            />

        </>
    );

}

export { TwoPlayer };