import React from "react";
import './CustomWord.css'
import { useNavigate, Link } from "react-router-dom";
// import { UseReducer } from './UseReducer'
import { TwoPlayerContext } from "../../Contexts/TwoPlayerContext/TwoPlayerContext";
import { Button } from "../../Components/Button/Button";
import { Footer } from "../../Components/Footer/Footer";
// import { Abecedario } from "../../Components/Abecedario/Abecedario";

function CustomWord() {
    let navigate = useNavigate();

    const [message, setMessage] = React.useState('')
    // const [state, dispatch] = React.useReducer(UseReducer.fxReducer, UseReducer.initialState);
    const {
        wordValue,
        setWordValue,
        onViewHome,
    } = React.useContext(TwoPlayerContext);

    const ViewHome = () => {
        onViewHome()
    }
    const onReturn = () => {
        navigate('/twoPlayer')
    }
    const onViewGame = () => {
        navigate('/twoPlayer/Game')
    }

    const onChange = (e) => {
        const { value } = e.target;
        /**
         * Letras mayúsculas
         * Letras minúsculas
         * Espacios
         * igual o mayor de 0 y se limita a 15 caracteres
            // const pattern = new RegExp('^[A-Za-zÑñÁáÉéÍíÓóÚúÜü ]{1,15}$');
        */
        const pattern = new RegExp('^[A-Za-zÑñ]{0,15}$'); //pattern sin espacios ni acentos
        if (pattern.test(value)) {
            setMessage('')
            setWordValue(value);
        }
    }

    const StartGame = () => {
        // Primera validacion, si input esta vacio entonces no es valido
        if (wordValue.length == 0) {
            setMessage('Ingresa una palabra')
            return;
        }

        // Segunda validacion, si la palabra tiene menos de 3 caracteres
        if (wordValue.length < 3) {
            setMessage('Palabra demaciado corta')
            return;
        }

        onViewGame()
    }

    return (
        <>
            <section className="ahorcado_eligePalabra">
                <label className="eligePalabra_texto" htmlFor="ingresaPalabra">Ingresa la palabra</label>
                <input value={wordValue} onChange={onChange} name="wordValue" className="eligePalabra_inputText" type="text" id="ingresaPalabra" maxLength="15" />
                {
                    !!message &&
                    <span className="eligePalabra_mensajeError">{message}</span>
                }
                <span className="length_word"> Total {15 - wordValue.length} de 15</span>
                <br />
                <Button handleClick={StartGame} name='Jugar'></Button>
            </section>
            <Footer
                ShowReturn={true}
                ShowHome={true}
                hangleHome={ViewHome}
                hangleReturn={onReturn}
            />
        </>
    )
}

export { CustomWord };
