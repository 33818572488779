import React from "react";
import { useNavigate } from "react-router-dom";
import './GameOver.css'
import { Footer } from "../Footer/Footer";
import { TwoPlayerContext } from "../../Contexts/TwoPlayerContext/TwoPlayerContext";
import { Button } from "../Button/Button";


function GameOver() {
    let navigate = useNavigate();

    const {
        onViewHome,
        gameOver,
        onViewTwoPlayerWin,
        wordValue
    } = React.useContext(TwoPlayerContext);


    return (
        gameOver &&
        <>
            <div className="gameover_modal">
                <div className="gameover_modal-body">
                    <h1 className="gameover__Message">GAME OVER</h1>
                    <h3 className="text_center">La palabra correcta era: <strong>{wordValue.toUpperCase()}</strong></h3>
                    <div className="gameover_constBtn">
                        <Button handleClick={onViewTwoPlayerWin} name='Jugar de nuevo' />
                    </div>
                    <Footer
                        ShowHome={true}
                        hangleHome={onViewHome}
                    />
                </div>
            </div>
        </>

    )
}

export { GameOver };