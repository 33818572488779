import { TwoPlayerProvider } from '../../Contexts/TwoPlayerContext/TwoPlayerContext'
import { Layout } from './Layout';
function LayoutStart({ children }) {
    return (
        <TwoPlayerProvider>
            <Layout>
                {children}
            </Layout>
        </TwoPlayerProvider>
    )
}

export { LayoutStart };
