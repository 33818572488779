import React from "react";
import './Button.css'

function Button({ name, classButon = 'btnColorGreen', handleClick, type = 'button' }) {

    const click = () => {
        if (!!handleClick) {
            handleClick()
        }
    }

    return (
        <button type={type} onClick={click} className={`buttonCont ${classButon}`}>{name}</button>
    )
}

export { Button }