import React from "react";
import './Abecedario.css'
import { TwoPlayerContext } from "../../Contexts/TwoPlayerContext/TwoPlayerContext";


function Abecedario() {

    const {
        arrayLetterPush,
        onSavePushLetther
    } = React.useContext(TwoPlayerContext);

    const onClick = (letter) => {
        onSavePushLetther(letter)
    }

    const onDisable = (letter) => {
        return arrayLetterPush.includes(letter)
    }

    return (
        <>
            {/* {arrayLetterPush} */}
            <div className="adivinaAdivina_abecedario">
                <div className="abecedario_container">
                    <button onClick={() => { onClick('A') }} disabled={onDisable('A')} className="abecedario_button">A</button>
                    <button onClick={() => { onClick('B') }} disabled={onDisable('B')} className="abecedario_button">B</button>
                    <button onClick={() => { onClick('C') }} disabled={onDisable('C')} className="abecedario_button">C</button>
                    <button onClick={() => { onClick('D') }} disabled={onDisable('D')} className="abecedario_button">D</button>
                    <button onClick={() => { onClick('E') }} disabled={onDisable('E')} className="abecedario_button">E</button>
                    <button onClick={() => { onClick('F') }} disabled={onDisable('F')} className="abecedario_button">F</button>
                    <button onClick={() => { onClick('G') }} disabled={onDisable('G')} className="abecedario_button">G</button>
                    <button onClick={() => { onClick('H') }} disabled={onDisable('H')} className="abecedario_button">H</button>
                    <button onClick={() => { onClick('I') }} disabled={onDisable('I')} className="abecedario_button">I</button>
                    <button onClick={() => { onClick('J') }} disabled={onDisable('J')} className="abecedario_button">J</button>
                    <button onClick={() => { onClick('K') }} disabled={onDisable('K')} className="abecedario_button">K</button>
                    <button onClick={() => { onClick('L') }} disabled={onDisable('L')} className="abecedario_button">L</button>
                    <button onClick={() => { onClick('M') }} disabled={onDisable('M')} className="abecedario_button">M</button>
                    <button onClick={() => { onClick('N') }} disabled={onDisable('N')} className="abecedario_button">N</button>
                    <button onClick={() => { onClick('Ñ') }} disabled={onDisable('Ñ')} className="abecedario_button">Ñ</button>
                    <button onClick={() => { onClick('O') }} disabled={onDisable('O')} className="abecedario_button">O</button>
                    <button onClick={() => { onClick('P') }} disabled={onDisable('P')} className="abecedario_button">P</button>
                    <button onClick={() => { onClick('Q') }} disabled={onDisable('Q')} className="abecedario_button">Q</button>
                    <button onClick={() => { onClick('R') }} disabled={onDisable('R')} className="abecedario_button">R</button>
                    <button onClick={() => { onClick('S') }} disabled={onDisable('S')} className="abecedario_button">S</button>
                    <button onClick={() => { onClick('T') }} disabled={onDisable('T')} className="abecedario_button">T</button>
                    <button onClick={() => { onClick('U') }} disabled={onDisable('U')} className="abecedario_button">U</button>
                    <button onClick={() => { onClick('V') }} disabled={onDisable('V')} className="abecedario_button">V</button>
                    <button onClick={() => { onClick('W') }} disabled={onDisable('W')} className="abecedario_button">W</button>
                    <button onClick={() => { onClick('X') }} disabled={onDisable('X')} className="abecedario_button">X</button>
                    <button onClick={() => { onClick('Y') }} disabled={onDisable('Y')} className="abecedario_button">Y</button>
                    <button onClick={() => { onClick('Z') }} disabled={onDisable('Z')} className="abecedario_button">Z</button>
                </div>
            </div>
        </>
    )
}

export { Abecedario }